<template>
  <div class="container kuan ">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="container kuan flex64">
        <div class="main">
          <!-- <div class="player listmain type-f3d f3d game-f3d game-f3d-2zh">

           
            <div class="kuaixuan_wrap p4">
              <section class="kx_item san">

                <section class="kx_right">
                  <div class="kx_row" :class="{shang:iIndex<selNumberData1.length-1}" v-for="(item,iIndex) in selNumberData1" :key="item.title+iIndex">
                    <em>{{item.title}}</em>
                    <span v-for="(kn,index) in item.arr" @click="setNumSel1(item,kn)" :class="{selected:kn.selected}" :key="item.title+index">{{kn.num}}</span>

            
                  </div>

                </section>

              </section>

            </div>
          </div> -->
          <!-- 注单号码 -->
          <div class="player listmain tuoduopeng" style="height:236px">
            <div class="data">
              <h3>注单号码 <span>共 <em class="red">{{resultData.length}} </em>种组合 </span></h3>

              <!-- <div class="dantuo-li">

          </div> -->
              <div class="dantuo-sc  h4zx">
                <div>
                  <span v-for="(item,index) in resultData" :key="index">{{item.label}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="other">
          <!-- <div class="zezhao" v-if="zezhao||weihu"></div> -->
          <div class="right-com">

            <div style="height:1px"></div>
            <section class="other_row">
              
              <span class="font_bold">配数</span>
              <span class="inlineblock" style="width:3px"></span>
              <input type="radio" v-model="pcq" value="chu" @change="pcqChange" class="t2">除
              <input type="radio" v-model="pcq" value="qu" @change="pcqChange" class="t2">取
            </section>
            <section class="other_row">
              <div v-for="(item, index) in selNumberInput1" style="display: inline-block" :key="item.title + 111">
                <span class="font_bold">{{ item.title }}</span>
                <span class="inlineblock" style="width: 3px"></span>
                <input class="w80" type="number" v-model="item.numbervalue" />
                <span class="inlineblock" style="width: 3px"></span>
              </div>
            </section>
            <section class="other_row flexrow">
              <span class="font_bold">不定位合分</span> 
              <section style="line-height:16px;margin-left:7px">
                <div>   <input class="t2" type="checkbox" :checked="condition.bdwhf==2" :value="2" @change="hefenChange">
              两数合 </div>
              <div>
                <input class="t2" type="checkbox" :checked="condition.bdwhf==3" :value="3" @change="hefenChange">
              三数合 
              </div>
              </section>
              <input type="number" class="w80" v-model="bdwhenfennumInput">
              <!-- <span class="dwhefen_num" v-for="(kn,index) in bdwhenfenNumArr" @click="setdwhefenNum1(kn)" :class="{selected:kn.selected}" :key="index+110">{{kn.label}}</span> -->
            </section>
         
           
            <section class="other_row">
              <input type="checkbox" @change="hanchange" :checked="condition.hancq=='chu'"  value="chu" class="t2">除
              <input type="checkbox" @change="hanchange" :checked="condition.hancq=='qu'"  value="qu" class="t2" >取
              <span class="inlineblock" style="width:8px"></span>
              四字现 <span class="font_bold">含</span>

              <input  type="number"  @change="qzchange" v-model="condition.haninput" class="w70">
              四字现 <span class="font_bold">复式</span>
              <input type="text" @change="qzchange" v-model="condition.fushiinput" class="w70">
            </section>
            <section class="other_row">
              <input type="checkbox" value="chu" @change="scchange" :checked="condition.sc=='chu'" class="t2">除
              <input type="checkbox" value="qu" @change="scchange" :checked="condition.sc=='qu'" class="t2">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">双重</span>)
              <span class="inlineblock" style="width:21px"></span>
          <input type="checkbox" value="chu" @change="sanchange" :checked="condition.sanchong=='chu'" class="t2">除
          <input type="checkbox" value="qu" @change="sanchange" :checked="condition.sanchong=='qu'" class="t2">取
          <!-- <span class="inlineblock" style="width:11px"></span> -->
          (<span class="font_bold">三重</span>)
          <span class="inlineblock" style="width:8px"></span>
          <input type="checkbox" value="chu" @change="sichange" :checked="condition.sichong=='chu'" class="t2">除
          <input type="checkbox" value="qu" @change="sichange" :checked="condition.sichong=='qu'" class="t2">取
         
          (<span class="font_bold">四重</span>)
            </section>
            <section class="other_row">
              <input type="checkbox" @change="xd2change" :checked="condition.xd2=='chu'" class="t2" value="chu">除
              <input type="checkbox" @change="xd2change" :checked="condition.xd2=='qu'" class="t2" value="qu">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">二兄弟</span>)
              <span class="inlineblock" style="width:8px"></span>
          <input type="checkbox" @change="xd3change" :checked="condition.xd3=='chu'" value="chu" class="t2">除
          <input type="checkbox" @change="xd3change" :checked="condition.xd3=='qu'" value="qu" class="t2">取
        
          (<span class="font_bold">三兄弟</span>)
          <span class="inlineblock" style="width:8px"></span>
            <input type="checkbox" @change="xd4change" :checked="condition.xd4=='chu'" value="chu" class="t2">除
            <input type="checkbox" @change="xd4change" :checked="condition.xd4=='qu'" value="qu" class="t2">取
            <!-- <span class="inlineblock" style="width:8px"></span> -->
            (<span class="font_bold">四兄弟</span>)
            </section>
            <section class="other_row">
              <input type="checkbox" class="t2" @change="dschange" :checked="condition.ds=='chu'" value="chu">除
              <input type="checkbox" class="t2" @change="dschange" :checked="condition.ds=='qu'" value="qu">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">对数</span>)

              <input type="text" v-model="condition.dsinput1" @change="dsinputchange($event,1)" :maxlength="2" class="w50">
              <input type="text" v-model="condition.dsinput2" @change="dsinputchange($event,2)" :maxlength="2" class="w50">
              <input type="text" v-model="condition.dsinput3" @change="dsinputchange($event,3)" :maxlength="2" class="w50">
              <input type="text" v-model="condition.dsinput4" @change="dsinputchange($event,4)" :maxlength="2" class="w50">
              <input type="text" v-model="condition.dsinput5" @change="dsinputchange($event,5)" :maxlength="2" class="w50">
            </section>
            <section class="other_row">  
              <div class="w240">
              <input type="checkbox" @change="danchange" :checked="condition.dancq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="danchange" :checked="condition.dancq=='qu'" class="t2" value="qu">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">单</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected"  v-for="(item,index) in danxianArr" :key="index+200">
           <span class="green">{{cqLabel[this.condition.dancq]}}   {{danxianlabel}}</span> 
           </div>
           <span class="inlineblock" style="width:30px"></span>
           <div class="w240">
            <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">双</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" v-for="(item,index) in shuangxianArr" :key="index+300">
           <span class="green">{{cqLabel[this.condition.shuangcq]}}   {{shuangxianlabel}}</span> 
          
           </div>
          </section>
            <!-- <section class="other_row">
              <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="shuangchange" :checked="condition.shuangcq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">双</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" v-for="(item,index) in shuangxianArr" :key="index+300">
           <span class="green">{{cqLabel[this.condition.shuangcq]}}   {{shuangxianlabel}}</span> 
            </section> -->
            <!-- <section class="other_row">
              <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='chu'" value="chu"  class="t2">除
              <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">小</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" v-for="(item,index) in xiaoxianArr" :key="index+400">
           <span class="green">{{cqLabel[this.condition.xiaocq]}}   {{xiaoxianlabel}}</span> 
            </section> -->
           
            <section  class="other_row">
              <div class="w240">
              <input type="checkbox" @change="dachange" :checked="condition.dacq=='chu'" value="chu" class="t2">除
              <input type="checkbox" @change="dachange" :checked="condition.dacq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">大</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" v-for="(item,index) in daxianArr" :key="index+500">
           <span class="green">{{cqLabel[this.condition.dacq]}}   {{daxianlabel}}</span> 
              </div>
              <span class="inlineblock" style="width:30px"></span>
              <div class="w240">
                <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='chu'" value="chu"  class="t2">除
              <input type="checkbox" @change="xiaochange" :checked="condition.xiaocq=='qu'" value="qu" class="t2">取
              <span class="inlineblock" style="width:8px"></span>
              (<span class="font_bold">小</span>)
              <span class="inlineblock" style="width:8px"></span>
              <input type="checkbox" class="t2" v-model="item.selected" :checked="item.selected" v-for="(item,index) in xiaoxianArr" :key="index+400">
           <span class="green">{{cqLabel[this.condition.xiaocq]}}   {{xiaoxianlabel}}</span> 
         
              </div>
          </section>
            <section>
              <div class="btnaa">
                <span class="button" @click="filterAllConditionData">生成</span>
                <span class="button" @click="resetAllCondition">重置</span>
              </div>
            </section>
          </div>
        </div>
      </div>
  
      <yushe />

    </div>

  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
import kxmixins from "../mixins/kxmixins";
import { siziLabelArr } from "../data";

export default {
  name: "sizixian",
  props: [""],
  mixins: [mixins, kxmixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      groupnames:'sizx',
      xianzi:4,
      yxLen: 4,
      chLen:4,
      isXian:true,
      rowsData: [],
       
      allData: [],
      oddData1: [],
      wzIndex: {
        qian: 0,
        bai: 1,
        shi: 2,
        ge: 3,
      },
      selNumberInput1: [
        { title: "取", numbervalue: "" },
        { title: "配", numbervalue: "" },
        { title: "配", numbervalue: "" },
        { title: "配", numbervalue: "" },
      ],
       //   配数全转  二字定
       selNumberData1: [
        {
          title: "取",
          eng: "qu",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "配",
          eng: "pei",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "配",
          eng: "pei",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
        {
          title: "配",
          eng: "pei",
          arr: [
            { num: "0", selected: false },
            { num: "1", selected: false },
            { num: "2", selected: false },
            { num: "3", selected: false },
            { num: "4", selected: false },
            { num: "5", selected: false },
            { num: "6", selected: false },
            { num: "7", selected: false },
            { num: "8", selected: false },
            { num: "9", selected: false },
          ],
        },
      ],
    
      danxianArr:[
        {selected:false,disable:false,},
        {selected:false,disable:false,},  
        {selected:false,disable:false,}, 
        {selected:false,disable:false,}, 
       ],
       shuangxianArr:[
        {selected:false,disable:false,}, 
        {selected:false,disable:false,},
        {selected:false,disable:false,}, 
        {selected:false,disable:false,},  
       ],
       xiaoxianArr:[
        {selected:false,disable:false,}, 
        {selected:false,disable:false,},
        {selected:false,disable:false,},  
        {selected:false,disable:false,}, 
       ],
       daxianArr:[
        {selected:false,disable:false,}, 
        {selected:false,disable:false,},
        {selected:false,disable:false,},  
        {selected:false,disable:false,}, 
       ],
    };
  },
  watch: {
   
    pan(val) {},
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
           
              if (item.money) {
                let obj = {
                  label: item.label,
                  Odds: item.Odds,
                  title: '四字现',
                  id: item.ResultID,
                  money: Number(item.money),
                };
                arr.push(obj);
              
              }
             
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        this.allData = [];
      // 四字现
      let arr1 = this.filterData(resAArr, 21031, 21745, siziLabelArr);
      this.allData=arr1;
 
      },
    },
  },

  created() {
    // 假数据--------------
    let erziding = [];
    for (let index = 21031; index < 21746; index++) {
      erziding.push({
        ResultID: index,
        Odds: 9.989,
      });
    }
    this.oddData1 = erziding;
  },

  methods: {
    checkNum(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      return newcount;
    },

    checkqianbai(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = str[0] + str[1] + "XX";
      return label;
    },
    checkqianshi(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = str[0] + "X" + str[1] + "X";
      return label;
    },
    checkqiange(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = str[0] + "XX" + str[1];
      return label;
    },
    checkbaige(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = "X" + str[0] + "X" + str[1];
      return label;
    },
    checkbaishi(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = "X" + str[0] + str[1] + "X";
      return label;
    },
    checkshige(num) {
      let newcount = num;
      if (num < 10) {
        newcount = "0" + num;
      }
      let str = newcount + "";

      let label = "XX" + str[0] + str[1];
      return label;
    },
  },
};
</script>
<style  scoped>
/* ------------------------other_row */

.other_row {
  width: 100%;
 
  padding: 0 4px;
  box-sizing: border-box;
  /* background: #edf4fe; */
}
input.t2 {
  position: relative;
  top: 2px;
  margin-right: 2px;
}
/* ------------------------------------------- */
.peishu_sec {
  margin-left: 30px;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  background: #edf4fe;
}
.xuan_way {
  height: 20px;
  display: flex;
  justify-content: center;
}
.kx_row.da {
  width: 70%;
  height: 100%;
  flex-wrap: wrap;
}
span.dwhefen_num {
  margin-left: 5px;
  display: inline-block;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  background: #f7f7f7;
  font-size: 13px;
  border: 1px solid rgb(187, 185, 185);
  font-weight: bold;
  border-radius: 50%;
}
.kx_row span {
  margin-left: 4px;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #f7f7f7;
  font-size: 15px;
  border: 1px solid rgb(187, 185, 185);
  font-weight: bold;
  border-radius: 5px;
}

.kx_right {
  flex: 1;
  height: 100%;
}

.kx_item.san {
  width: 100%;
}
.kx_item.er {
  flex: 1;
}
.kx_left {
  width: 110px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-right: 1px solid lightgray;
}

.kx_item {
  height: 100%;
  display: flex;
}

.kuaixuan_wrap {
  padding-bottom: 2px;
  display: flex;
  margin-top: 1px;
  width: 100%;
  height: 160px;
  border: 1px solid lightgray;
}
li.liqx i,
li.w2 i {
  font-size: 20px;
  color: #666;
}
li.w2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.liqx {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listmain > .data > ul.custom_ul.kuaixuan {
  height: 26px;
}
.listmain > .data > ul.custom_ul {
  width: 98%;
  height: 260px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data {
  /* width: 90%; */
}
.listmain .data ul li {
  width: 10%;
  height: 26px;
}
.listmain .data ul li.w2 {
  width: 2%;
}
</style>